body{
    .dd_container{
        background: #ffffff;
        &.small-menu{
            transform: translate3d(0, 58px, 0px) !important;
            width: 100%;
            .menu-item{
                .sub-menu-items{
                    display: flex;
                }
            }
        }
    }
}
.top_bar{
    background-color: #fff;
    -webkit-box-shadow: 0px 4px 40px rgb(0 0 0 / 7%);
    box-shadow: 0px 4px 40px rgb(0 0 0 / 7%);
    z-index: 8;
    display: flex;
    justify-content: space-between;
    .welcome_text{
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 38px;
        color: #8D8D8D;
        .text-black {
            color: #000000;
        }
    }
    .display-time{
        display: flex;
        gap: 7px;
        .time{
            color: #8D8D8D;
            font-size: 12px;
        }
    }
}
.language_menu {
    margin-right: 1rem;
    div{
        display: flex;
        svg {
            width: 20px;
            height: 20px;
        }
        span{
            margin-left: 6px;
        }
    }
}
.language_menu_list {
    display: flex;
    flex-flow: column;
    a.block {
        display: flex;
        padding-left: 12px;
        padding-top: 5px;
        padding-bottom: 5px;
        svg {
            width: 20px;
            height: 20px;
        }
        span.lang_name{
            margin-left: 6px;
        }
    }
}
.mobile-menu-selector{
    fill: #8D8D8D;
}
.menu-item {
    width: 100%;
    padding: 3px 20px;
    position: relative;
    .menu-link{
        border-radius: 8px;
        padding: 10px 15px;
        font-weight: 500;
        .menu__name{
            color: #2c323f;
            font-size: 15px;
        }
        &.have-sub-menu{
            &:after{
                content: '';
                position: absolute;
                width: 15px;
                height: 15px;
                background-image: url("/images/svg/angle-right.svg");
                background-repeat: no-repeat;
                display: block;
                top: 16px;
                right: 36px;
            }
        }
    }
    .sub-menu-items{
        display: none;
        flex-flow: column;
        gap: 15px;
        margin: 15px 24px;
        .sub-menu-item{
            user-select: none;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 4px;
            .menu__name{
                font-size: 14px;
            }
        }
    }
    &.active{
        .menu-link{
            background-color: #7366ff;
            -webkit-transition: all 0.5s ease;
            transition: all 0.5s ease;
            .menu__name{
                color: #ffffff;
                -webkit-transition: all 0.3s ease;
                transition: all 0.3s ease;
            }
            .menu__icon{
                fill: #ffffff;
                -webkit-transition: all 0.3s ease;
                transition: all 0.3s ease;
            }
            &.have-sub-menu{
                background-color: #dad6ff;
                .menu__name{
                    color: #7366ff;
                }
                .menu__icon{
                    fill: #7366ff;
                }
                &:after{
                    background-image: url("/images/svg/angle-down.svg");
                }
            }
        }
        .sub-menu-items{
            display: flex;
            .sub-menu-item{
                &.active{
                    .menu__name{
                        color: #7366ff;
                        -webkit-transition: all 0.3s ease;
                        transition: all 0.3s ease;
                    }
                    .menu__icon{
                        fill: #7366ff;
                        -webkit-transition: all 0.3s ease;
                        transition: all 0.3s ease;
                    }
                }
            }
        }
    }
    &.hover{
        .menu-link{
            &.have-sub-menu{
                background-color: #dad6ff;
                .menu__name{
                    color: #7366ff;
                    -webkit-transition: all 0.3s ease;
                    transition: all 0.3s ease;
                }
                .menu__icon{
                    fill: #7366ff;
                    -webkit-transition: all 0.3s ease;
                    transition: all 0.3s ease;
                }
                &:after{
                    background-image: url("/images/svg/angle-down.svg");
                }
            }
        }
        .sub-menu-items{
            display: flex;
        }
    }
}

.dd__wrapper{
    .drop-down-caret-icon{
        fill: #ffffff;
    }
    span{
        color: #ffffff;
    }
}

nav{
    li{
        &.group{
            a{
                border-bottom: 1px solid transparent;
            }
            &.active{
                @apply opacity-70;
                border-color: #ffffff;
            }
        }
    }
}

.dd__wrapper{
    .select_user{
        span{
            &.hidden{
                display: none;
                @media (min-width: 720px) {
                    display: flex;
                }
            }
        }
    }
}

.sticky {
    @apply fixed z-[9999] bg-white bg-opacity-80 transition;
    backdrop-filter: blur(5px);
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
    .drop-down-caret-icon{
        fill: #0f172a;
    }
    span{
        color: #0f172a;
    }
    .navbar-logo {
        @apply py-2;
    }
    #navbarToggler span {
        @apply bg-dark;
    }
    #navbarCollapse li a {
        @apply text-dark hover:text-primary hover:opacity-100;
    }
    #navbarCollapse li .ud-menu-scroll.active {
        @apply text-primary opacity-100;
    }
    .loginBtn {
        @apply text-dark hover:text-primary hover:opacity-100;
    }
    .signUpBtn {
        @apply bg-primary text-white hover:bg-dark hover:text-white;
    }
    nav{
        li{
            &.group{
                &.active{
                    color: #4f46e5;
                }
            }
        }
    }
}
#navbarCollapse li .ud-menu-scroll.active {
    @apply opacity-70;
}

.navbarTogglerActive > span:nth-child(1) {
    @apply top-[7px] rotate-45 transform;
}
.navbarTogglerActive > span:nth-child(2) {
    @apply opacity-0;
}
.navbarTogglerActive > span:nth-child(3) {
    @apply top-[-8px] rotate-[135deg];
}

.ud-header{
    .help-desk-logo{
        display: none;
        &.white{
            display: block;
        }
    }
    &.sticky{
        .help-desk-logo{
            display: block;
            &.white{
                display: none;
            }
        }
    }
}
